import React, { Component } from 'react'
import { Row, Col, message, Spin, Select, Modal, Input, Button } from 'antd';
import { pushDzfp, printInvoice, createDzfpPdf } from '../../../../api'
import utils from "../../../../utils"
import './details.less'

const { Option } = Select;

let printTest = window.printTest

export default class Details extends Component {
    constructor(props) {
        super(props)
        this.state = {
            info: {},
            list: [],
            loading: false,
            xml: '',
            fpmb: "00",
            invoiceType: "",
            showSl: false,
            zbj_zzsp: 0,
            ybj_zzsp: 0,
            zbj_zzsz: 0,
            ybj_zzsz: 0,
        }
    }

    componentWillMount() {
        this.fpSize()
        let info = this.props.detailInfo
        debugger
        // console.log("info", info)
        let list = JSON.parse(info.fyxmJson)
        this.setState({
            info,
            list,
        })

    }

    componentWillUpdate() {
        this.fpSize()
    }

    fpSize = () => {
        this.state.zbj_zzsp = localStorage.getItem("zbj_zzsp")||0
        this.state.ybj_zzsp = localStorage.getItem("ybj_zzsp")||0
        this.state.zbj_zzsz = localStorage.getItem("zbj_zzsz")||0
        this.state.ybj_zzsz = localStorage.getItem("ybj_zzsz")||0
    }

    pushInvoice = data => {
        const { fpdm, fphm, gfkhyx: email } = data
        const param = { fpdm, fphm, email }
        this.setState({ loading: true })
        pushDzfp(param).then(res => {
            if (res.code == "0000") {
                message.success(res.msg)
            } else {
                message.error(res.msg)
            }
            this.setState({ loading: false })
        })
    }

    //金额转换大写
    resSetMoney = () => {
        var num = (this.state.info.hjje + this.state.info.hjse) || 0
        var strOutput = "",
            strUnit = '仟佰拾亿仟佰拾万仟佰拾元角分';
        num += "00";
        var intPos = num.indexOf('.');
        if (intPos >= 0) {
            num = num.substring(0, intPos) + num.substr(intPos + 1, 2);
        }
        strUnit = strUnit.substr(strUnit.length - num.length);
        for (var i = 0; i < num.length; i++) {
            strOutput += '零壹贰叁肆伍陆柒捌玖'.substr(num.substr(i, 1), 1) + strUnit.substr(i, 1);
        }
        return strOutput.replace(/零角零分$/, '整').replace(/零[仟佰拾]/g, '零').replace(/零{2,}/g, '零').replace(/零([亿|万])/g, '$1').replace(/零+元/, '元').replace(/亿零{0,3}万/, '亿').replace(/^元/, "零元")

    }
    downPdf = (url) => {
        const oa = document.createElement('a');
        oa.href = url;
        oa.setAttribute('target', '_blank');
        document.body.appendChild(oa);
        oa.click();
        oa.remove();
    }
    handleDownExcel = () => {
        let { fpdm, fphm } = this.state.info
        createDzfpPdf({ fpdm, fphm}).then(res => {
            if (res.code === "0000") {
                message.success(res.msg)
                this.downPdf(res.resultObj)
            } else {
                message.error(res.msg)
            }
        })
    };

    handleDownOfd = (type)=>{
        let { fpdm, fphm } = this.state.info
        createDzfpPdf({ fpdm, fphm,type}).then(res => {
            if (res.code === "0000") {
                message.success(res.msg)
                this.downPdf(res.resultObj)
            } else {
                message.error(res.msg)
            }
        })
    }

    zpdy = (dy) => {
        this.printFp(window.ZzszFpdy, dy)
    }

    ppdy = (dy) => {
        this.printFp(window.ZzspFpdy, dy)
    }

    printFp = (fun, dy) => {
        this.setState({ loading: true })
        printInvoice({ serialNumber: this.state.info.fpqqlsh }).then(res => {
            if (res.code == "0000") {
                let xml = res.resultObj
                try{
                    fun(xml, dy)
                }catch{
                    console.log('请手动安装！')
                }
            } else {
                message.error(res.msg)
            }
            this.setState({ loading: false })
        })
    }

    //专票清单
    zpqddy = (dy) => {
        this.printQd(window.Zzszqddy, dy, 0, 0, this.state.fpmb)
    }

    //普票清单
    ppqddy = (dy) => {
        this.printQd(window.Zzspqddy, dy, 5, 5, this.state.fpmb)
    }

    printQd = (fun, dy, k, w, fpmb) => {
        this.setState({ loading: true })
        printInvoice({ serialNumber: this.state.info.fpqqlsh }).then(res => {
            if (res.code == "0000") {
                let xml = res.resultObj
                fun(xml, dy, k, w, fpmb)
            } else {
                message.error(res.msg)
            }
            this.setState({ loading: false })
        })
    }

    // handleChange=(fpmb)=> {
    //    this.setState({fpmb})
    // }

    render() {

        let bg3 = {
            width: "100%",
            height: "100px",
            backgroundImage: `url(${require("./../../../../assets/icon/dz.png")})`,
            overflow: "hidden",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
        };
        return (
            <Spin spinning={this.state.loading} size="large">
                <Row className="details-first">
                    <Col style={bg3} className="bg-box">
                        <div>{utils.myFaPiaoType(this.state.info.invoiceType)}</div>
                    </Col>
                    <Col>
                        <label><span>*</span>消费者邮箱：</label><span>{this.state.info.gfkhyx}</span>
                    </Col>
                    <Col>
                        <label><span>*</span>消费者手机号：</label><span>{this.state.info.gfkhdh}</span>
                    </Col>
                </Row>
                <Row className="details-second">
                    <Col>
                        <div>
                            <span>购买方</span>
                        </div>
                        <div>
                            <div><label>购买方名称：</label><span>{this.state.info.ghdwmc}</span></div>
                            <div><label>纳税人识别号：</label><span>{this.state.info.ghdwsbh}</span></div>
                            <div><label>地址、电话：</label><span>{this.state.info.ghdwdzdh}</span></div>
                            <div><label>开户及帐号：</label><span>{this.state.info.ghdwyhzh}</span></div>
                        </div>
                        <div>
                            <span>密码区</span>
                        </div>
                        <div className="box_psd" style={{ padding: 0 }}>
                            {
                                (this.state.info.status == 5 || this.state.info.status == 6) ? (this.state.info.negativeSkm) : (this.state.info.skm)
                            }
                        </div>

                    </Col>
                </Row>
                <Row className="details-third">
                    <Col>
                        <Row>
                            <Col span={8}>
                                <div>货物或应税劳务、服务名称</div>
                            </Col>
                            <Col span={2}>
                                <div>规格型号</div>
                            </Col>
                            <Col span={2}>
                                <div>单位</div>
                            </Col>
                            <Col span={2}>
                                <div>数量</div>
                            </Col>
                            <Col span={3}>
                                <div>单价（不含税）</div>
                            </Col>
                            <Col span={3}>
                                <div>金额（不含税）</div>
                            </Col>
                            <Col span={2}>
                                <div>税率</div>
                            </Col>
                            <Col span={2}>
                                <div>税额</div>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        {
                            this.state.list.map((item, index) => {
                                return (
                                    <Row key={index}>
                                        <Col span={8}>
                                            <div>{item.spsm ? `*${item.spsm}*${item.spmc}` : item.spmc}</div>
                                        </Col>
                                        <Col span={2}>
                                            <div>{item.ggxh}</div>
                                        </Col>
                                        <Col span={2}>
                                            <div>{item.dw}</div>
                                        </Col>
                                        <Col span={2}>
                                            <div>{item.spsl}</div>
                                        </Col>
                                        <Col span={3}>
                                            <div>{(item.dj).toFixed(2)}</div>
                                        </Col>
                                        <Col span={3}>
                                            <div>{(item.je).toFixed(2)}</div>
                                        </Col>
                                        <Col span={2}>
                                            <div>{parseFloat(item.sl) * 100 + "%"}</div>
                                        </Col>
                                        <Col span={2}>
                                            <div>{item.se}</div>
                                        </Col>
                                    </Row>
                                )
                            })
                        }

                    </Col>

                </Row>
                <Row>
                    <Col style={{ height: 32, border: "1px solid rgba(210, 210, 210, 1)", borderTop: 0 }}></Col>
                </Row>
                <Row style={{ border: "1px solid rgba(210, 210, 210, 1)", borderTop: 0 }} className="details-fourth">
                    <Col span={16}>
                        <span>合计</span>
                    </Col>
                    <Col span={5}>
                        <span>金额：￥{this.state.info.hjje}</span>
                    </Col>
                    <Col span={3}>
                        <span>税额：￥{this.state.info.hjse}</span>
                    </Col>
                </Row>
                <Row style={{ border: "1px solid rgba(210, 210, 210, 1)", borderTop: 0 }} className="details-five">
                    <Col span={5}>
                        <span>价格合计（大写）</span>
                    </Col>
                    <Col span={14}>
                    </Col>
                    <Col span={5}>
                        <span>{this.resSetMoney()}</span>
                    </Col>
                </Row>
                <Row className="details-second details-sex">
                    <Col>
                        <div>
                            <span>销售方</span>
                        </div>
                        <div>
                            <div><label>销方名称：</label><span>{this.state.info.xhdwmc}</span></div>
                            <div><label>纳税人识别号：</label><span>{this.state.info.xhdwsbh}</span></div>
                            <div><label>地址、电话：</label><span>{this.state.info.xhdwdzdh}</span></div>
                            <div><label>开户及帐号：</label><span>{this.state.info.xhdwyhzh}</span></div>
                        </div>
                        <div>
                            <span>备注</span>
                        </div>
                        <div style={{ padding: 20 }}>
                            {this.state.info.bz}
                        </div>
                    </Col>
                </Row>
                <Row className="details-last">
                    <Col span={16} >
                        <div><label>开票人：</label><span>{this.state.info.kpr}</span></div>
                        <div><label>收款人：</label><span>{this.state.info.skr}</span></div>
                        <div><label>复核人：</label><span>{this.state.info.fhr}</span></div>
                    </Col>
                    <Col span={8}>
                        <span>销售方：&nbsp;&nbsp;（章）</span>
                    </Col>
                </Row>
                <Row className="details-last-botton">
                    <Col >

                        {
                            this.state.info.invoiceType == "004" ? (
                                <div>
                                    <span className="btnSpan" onClick={() => { this.zpdy(0) }}>专票预览</span>
                                    <span className="btnSpan" onClick={() => { this.zpdy(1) }}>专票打印</span>
                                    <span className="btnSpan" onClick={() => { this.setState({ showSl: true }) }}>打印设置</span>
                                    {
                                        this.state.list.length > 8 ? (
                                            <div style={{ display: "inline-block" }}>
                                                <span className="btnSpan" onClick={() => { this.zpqddy(0) }}>专票清单预览</span>
                                                <span className="btnSpan" onClick={() => { this.zpqddy(1) }}>专票清单打印</span>
                                            </div>
                                        ) : null
                                    }
                                </div>
                            ) : (
                                    this.state.info.invoiceType == "007" ? (
                                        <div>
                                            <span className="btnSpan" onClick={() => { this.ppdy(0) }}>普票预览</span>
                                            <span className="btnSpan" onClick={() => { this.ppdy(1) }}>普票打印</span>
                                            <span className="btnSpan" onClick={() => { this.setState({ showSl: true }) }}>打印设置</span>
                                            {
                                                this.state.list.length > 8 ? (
                                                    <div style={{ display: "inline-block" }}>
                                                        <span className="btnSpan" onClick={() => { this.ppqddy(0) }}>普票清单预览</span>
                                                        <span className="btnSpan" onClick={() => { this.ppqddy(1) }}>普票清单打印</span>
                                                    </div>
                                                ) : null
                                            }

                                            {/* {
                                                this.state.list.length>8?(
                                                    <div style={{display:"inline-block"}}>
                                                        清单尺寸：
                                                        <Select defaultValue="00" style={{ width: 120 }} onChange={this.handleChange}>
                                                            <Option value="00">57-152</Option>
                                                            <Option value="01">57-177</Option>
                                                            <Option value="02">76-152</Option>
                                                            <Option value="03">76-177</Option>
                                                            <Option value="06">76-177.8</Option>
                                                            <Option value="07">57-177.8</Option>
                                                            <Option value="08">76-152.4</Option>
                                                        </Select>
                                                    </div>
                                                ):null
                                            } */}
                                        </div>
                                    ) : (
                                            <div>
                                                <span className="btnSpan" onClick={() => this.pushInvoice(this.state.info)}>发送给消费者</span>
                                                <span className="btnSpan" onClick={() => { this.handleDownExcel() }}>PDF下载</span>
                                                <span className="btnSpan" onClick={() => { this.handleDownOfd('OFD') }}>OFD下载</span>
                                                <span className="btnSpan" onClick={() => { this.handleDownOfd('XML') }}>XML下载</span>
                                            </div>
                                        )
                                )

                        }
                    </Col>
                </Row>
                {
                    this.state.showSl ? (<Modal title="打印位置尺寸设置(本机)" visible={this.state.showSl} footer={null} onCancel={() => { this.setState({ showSl: false }) }}>
                        <div className="pageContent" id="printTest">
                            <span>增值税普票边距设置</span>
                            <div>
                                <label htmlFor="zbj_zzsp">整体上边距(mm)：</label>
                                <Input autoComplete="off" id="zbj_zzsp" defaultValue={this.state.zbj_zzsp} className="number required" type="number" />
                            </div>
                            <div>
                                <label htmlFor="ybj_zzsp">整体左边距(mm)：</label>
                                <Input autoComplete="off" id="ybj_zzsp" defaultValue={this.state.ybj_zzsp} className="number required" type="number" />
                            </div>
                            <div >
                                <Button type="primary" onClick={() => {
                                    printTest.saveCookie('007', true)
                                    this.setState({ showSl: false })
                                }}>保存普票边距设置</Button>
                            </div>

                            <span>增值税专票边距设置</span>
                            <div>
                                <label htmlFor="zbj_zzsz">整体上边距(mm)：</label>
                                <Input autoComplete="off" id="zbj_zzsz" defaultValue={this.state.zbj_zzsz} className="number required" type="number" />
                            </div>
                            <div>
                                <label htmlFor="ybj_zzsz">整体左边距(mm)：</label>
                                <Input autoComplete="off" id="ybj_zzsz" defaultValue={this.state.ybj_zzsz} className="number required" type="number" />
                            </div>
                            <div >
                                <Button type="primary" onClick={() => {
                                    printTest.saveCookie('004', true)
                                    this.setState({ showSl: false })
                                }}>保存专票边距设置</Button>
                            </div>
                        </div>
                    </Modal>) : null
                }
            </Spin>
        )
    }
}


