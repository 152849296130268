import ajax from "./ajax"
// import action from "../actions/action"
// import store from "../store"

let HTBASE;
let KPBASE;
let INVOICE;
let WX_KPBASE;
if(process.env.NODE_ENV==="development"){
  HTBASE = process.env.REACT_APP_HTBASE
  KPBASE = process.env.REACT_APP_KPBASE
  WX_KPBASE = process.env.REACT_APP_WX_KPBASE
  INVOICE = process.env.REACT_APP_INVOICE
}else if(process.env.NODE_ENV==="production"){
    let locationHref = window.location.href
    if(locationHref.startsWith("http:")){
        window.location.href = locationHref.replace('http:',"https:")
    }
    if(locationHref.includes(process.env.REACT_APP_SEARCH)){
        HTBASE = process.env.REACT_APP_HTBASE_PRO
        KPBASE = process.env.REACT_APP_KPBASE_PRO
        WX_KPBASE = process.env.REACT_APP_WX_KPBASE_PRO
    }else{
        HTBASE = process.env.REACT_APP_HTBASE
        KPBASE = process.env.REACT_APP_KPBASE
        WX_KPBASE = process.env.REACT_APP_WX_KPBASE
    }
    INVOICE= process.env.REACT_APP_INVOICE
}
//文件上传
export const fileUploadUrl='/api/analysis/wordPDFFile'
//发起签约
export const selectCustomer=({sponsorId,sponsorName,contractUrl,contractName,contractSerial,contractTime,departmentId,contractType,signtoryJson,signtoryNum,createBy,updateBy})=>ajax(HTBASE+'/ecContractOrder/insertContractOrder',{sponsorId,sponsorName,contractUrl,contractName,contractSerial,contractTime,departmentId,contractType,signtoryJson,signtoryNum,createBy,updateBy},'post',true)
//选择签署方
export const selectSign=({serial})=>ajax(HTBASE+'/ecContractOrderDetails/selectOrderDetails',{serial},'post',false)
//注册
export const register=({loginName,userName,userPhone,idCard,password})=>ajax(HTBASE+'/system/register',{loginName,userName,userPhone,idCard,password},'post',true)
//登录
export const login=({loginName,password})=>ajax(HTBASE+'/system/login',{loginName,password},'post',true)
export const officialLogin=(data)=>ajax(HTBASE+'/system/officialLogin',data,'post',true)
//验证码
export const verificationCode=()=>ajax(HTBASE+'/system/verificationCode',{},'get',true)
//菜单添加
export const menuAdd=({parentResourcesNo,resourcesName,resourcesUrl,resourcesType,status})=>ajax(HTBASE+'/resources/add',{parentResourcesNo,resourcesName,resourcesUrl,resourcesType,status},'post',true)
//菜单查询
export const menuGet=()=>ajax(HTBASE+'/resources/select/all',{},'post',true)
//角色列表
export const roleGet=(data)=>ajax(HTBASE+'/role/findList',data,'post',true)
//用户列表
export const userGet=({loginName,userName,userPhone,page,companyName})=>ajax(HTBASE+'/user/findList',{loginName,userName,userPhone,page,companyName},'post',true)
//角色新增
export const addRoleAll=({roleName,resourcesNoList,status,remark})=>ajax(HTBASE+'/role/add',{roleName,resourcesNoList,status,remark},'post',true)
//角色删除
export const delRole=({id})=>ajax(HTBASE+'/role/delete',{id},'post',true)
//角色修改
export const updateRole=({roleName,id,resourcesNoList,remark,status})=>ajax(HTBASE+'/role/update',{roleName,id,resourcesNoList,remark,status},'post',true)
//角色详情
export const detailRole=({id})=>ajax(HTBASE+'/role/getById',{id},'post',true)
//用户删除
export const delUser=({id})=>ajax(HTBASE+'/user/delete',{id},'post',true)
//用户新增
export const addUser=({loginName,userName,userPhone,idCard,password,roleUuid,entNo})=>ajax(HTBASE+'/user/add',{loginName,userName,userPhone,idCard,password,roleUuid,entNo},'post',true)
//用户修改
export const updateUser=({id,uuid,loginName,userPhone,userName,password,idCard,roleUuid,entNo})=>ajax(HTBASE+'/user/update',{id,uuid,loginName,userPhone,userName,password,idCard,roleUuid,entNo},'post',true)
//用户详情
export const detailUser=({id})=>ajax(HTBASE+'/user/getById',{id},'post',true)
//菜单添加
export const addMenu=({parentResourcesNo,resourcesName,resourcesUrl,resourcesType,status,imgName})=>ajax(HTBASE+'/resources/add',{parentResourcesNo,resourcesName,resourcesUrl,resourcesType,status,imgName},'post',true)
//菜单删除
export const delMenu=({id})=>ajax(HTBASE+'/resources/delete',{id},'post',true)
//菜单修改
export const updateMenu=({id,parentResourcesNo,resourcesName,resourcesUrl,resourcesType,status,imgName})=>ajax(HTBASE+'/resources/update',{id,parentResourcesNo,resourcesName,resourcesUrl,resourcesType,status,imgName},'post',true)
//菜单查询
export const selectMenu=({id})=>ajax(HTBASE+'/resources/getById',{id},'post',true)
//公司印章
export const companySeal=({number,companyName})=>ajax(HTBASE+'/ecContractOrderDetails/GenerateCommonSteal',{number,companyName},'post',true)
//个人印章
export const personalSeal=({name})=>ajax(HTBASE+'/ecContractOrderDetails/generatePersonalSteal',{name},'post',true)
//首页合同
export const selectSponsorIdCount=({sponsorId,type,stime,etime,contractName,sponsorName,datailsName,contractState})=>ajax(HTBASE+'/ecContractOrder/selectSponsorIdCount',{sponsorId,type,stime,etime,contractName,sponsorName,datailsName,contractState},'post',true)
//所有合同
export const selectSponsorId=({sponsorId,type,stime,etime,contractName,sponsorName,datailsName,contractState})=>ajax(HTBASE+'/ecContractOrder/selectSponsorId',{sponsorId,type,stime,etime,contractName,sponsorName,datailsName,contractState},'post',true)
//签署并发送
export const sign=({OrderResult,serial})=>ajax(HTBASE+'/ecContractOrderDetails/updateOrderDetails',{OrderResult,serial},'post',false)
//合同详情
export const contractDetail=({contractSerial})=>ajax(HTBASE+'/ecContractOrder/selectDetailed',{contractSerial},'post',true)
//短信提醒
export const smsRemind=({serial,phone})=>ajax(HTBASE+'/ecContractOrder/contractRemind',{serial,phone},'post',true)
//批量操作
export const selectBatch=({OrderResult})=>ajax(HTBASE+'/ecContractOrder/selectBatch',{OrderResult},'post',false)
//企业信息
export const companyInfo=({id})=>ajax(HTBASE+'/ecEnterprise/select',{id},'post',false)
//企业注册
export const companyregister=({phone,email,loginName,name,registeredNo,address,authCode,checkCode})=>ajax(HTBASE+'/ecEnterprise/register',{phone,email,loginName,name,registeredNo,address,authCode,checkCode},'post',false)
//企业验证码注册
export const companyCode=({phone})=>ajax(HTBASE+'/ecEnterprise/redisPhone',{phone},'post',false)
//开票管理系统

//网上领票列表
export const invoiceBuySync = ({kpzdbs}) => ajax(KPBASE+'/invoiceBuy/invoiceBuySync',{kpzdbs},'post',true)
//税控盘管理
export const queryTaxPlateByPage = ({nsrsbh,nsrmc,plateNo,page,rows}) => ajax(KPBASE+'/tbTaxPlateManage/queryTaxPlateByPage',{nsrsbh,nsrmc,plateNo,page,rows},'post',true)
//税控盘查询
export const queryTaxPlateByNsrsbh = () => ajax(KPBASE+'/tbTaxPlateManage/queryTaxPlateByUser',{},'post',true)
//领购发票
export const invoiceBuy = data => ajax(KPBASE+'/invoiceBuy/invoiceBuy',data,'post',true)
//商品编码列表
export const queryGoodsNoByPage = ({spmc,spbm,page,rows,nsrsbh}) => ajax(KPBASE+'/goodsNo/queryGoodsNoByPage',{spmc,spbm,page,rows,nsrsbh},'post',true)
//新增商品编码
export const addGoodsNo = data => ajax(KPBASE+'/goodsNo/addGoodsNo',data,'post',true)
//修改商品编码
export const updateGoodsNo = data => ajax(KPBASE+'/goodsNo/updateGoodsNo',data,'post',true)
//删除商品编码
export const deleteGoodsNo = id => ajax(KPBASE+'/goodsNo/deleteGoodsNo',{id},'post',true)
//发票列表
export const queryInvoiceByPage = data => ajax(KPBASE+'/simpleInvoice/queryInvoiceByPage',data,'post',true)
//开具发票常用购方信息查询
export const queryBuyerInfoByPage = ({buyerName,page,rows}) => ajax(KPBASE+'/simpleInvoice/queryBuyerInfoByPage',{buyerName,page,rows},'post',true)
//获取当前用户的商品编码 分页
export const queryGoodsNoByUser = ({spmc,page,rows}) => ajax(KPBASE+'/goodsNo/queryGoodsNoByUser',{spmc,page,rows},'post',true)
//查询当前登陆用户的企业信息
export const queryEnterpriseInfoByUser = () => ajax(KPBASE+'/simpleInvoice/queryEnterpriseInfoByUser',{},'post',true)
//推送
export const pushDzfp = ({fpdm,fphm,email}) => ajax(KPBASE+'/simpleInvoice/pushDzfpEmail',{fpdm,fphm,email},'post',true)
//开票
export const createInvoice = ({ghdwdzdh,ghdwmc,ghdwsbh,ghdwyhzh,goodsVos,invoiceType,kplx,tspz,gfkhyx,bz,kpr,skr,fhr,xhdwsbh,xhdwdzdh,xhdwmc,xhdwyhzh,param2}) => ajax(KPBASE+'/simpleInvoice/createInvoiceAdmin',{ghdwdzdh,ghdwmc,ghdwsbh,ghdwyhzh,goodsVos,invoiceType,kplx,tspz,gfkhyx,bz,kpr,skr,fhr,xhdwsbh,xhdwdzdh,xhdwmc,xhdwyhzh,param2},'post',true)
//税局公共商品编码查询 一级分类
export const queryFirstLevelPublicGoodsNo = () => ajax(KPBASE+'/goodsNo/queryFirstLevelPublicGoodsNo',{},'post',true)
//税局公共商品编码查询 字分类
export const queryPublicCategoryGoodsLevelList = ({goodsNo}) => ajax(KPBASE+'/goodsNo/queryPublicCategoryGoodsLevelList',{goodsNo},'post',true)
//税局公共商品编码查询 按名称或商品分类
export const queryPublicGoodsNoByPage = ({name,goodsNo,page,rows}) => ajax(KPBASE+'/goodsNo/queryPublicGoodsNoByPage',{name,goodsNo,page,rows},'post',true)
//负数发票 冲红
export const createNegativeInvoice = ({fpdm,fphm,xxbbh}) => ajax(KPBASE+'/simpleInvoice/createNegativeInvoice',{fpdm,fphm,xxbbh},'post',true)
// 根据发票代码号码查询发票详情
export const queryInvoiceInfoByFpdmAndFphm = ({fpdm,fphm}) => ajax(KPBASE+'/simpleInvoice/queryInvoiceInfoByFpdmAndFphm',{fpdm,fphm},'post',true)
//查询红冲
export const redInfoPaperApply = (data) => ajax(KPBASE+'/copyAndReport/redInfoPaperApply',data,'post',true)
export const redInfoPaperQuery = (data) => ajax(KPBASE+'/copyAndReport/redInfoPaperQuery',data,'post',true)
//汇总统计
export const invoiceCollect = (data) => ajax(KPBASE+'/copyAndReport/invoiceCollect',data,'post',true)
//发票作废
export const invoiceVoid = ({kpzdbs,fplxdm,zflx,fpdm,fphm}) => ajax(KPBASE+'/copyAndReport/invoiceVoid',{kpzdbs,fplxdm,zflx,fpdm,fphm},'post',true)
// 重开发票
export const invoiceReapet = (param) => ajax(KPBASE+'/simpleInvoice/afreshCreateInvoice',param,'post',true)
//购票单位列表
export const queryByPage = ({buyerName,buyerRegiestNo,page,rows}) => ajax(KPBASE+'/buyerManage/queryByPage',{buyerName,buyerRegiestNo,page,rows},'post',true)
//新增购票单位
export const buyerManageAdd = ({buyerName,buyerRegiestNo,buyerAddress,buyerPhone,buyerBankName,buyerBankNo}) => ajax(KPBASE+'/buyerManage/add',{buyerName,buyerRegiestNo,buyerAddress,buyerPhone,buyerBankName,buyerBankNo},'post',true)
//删除购票单位
export const buyerManageDelete = id => ajax(KPBASE+'/buyerManage/delete',{id},'post',true)
//更新购票单位
export const buyerManageUpdata = ({id,buyerName,buyerRegiestNo,buyerAddress,buyerPhone,buyerBankName,buyerBankNo}) => ajax(KPBASE+'/buyerManage/update',{id,buyerName,buyerRegiestNo,buyerAddress,buyerPhone,buyerBankName,buyerBankNo},'post',true)
// 同步登记信息
export const queryTaxPlateByNsrsbhFromThird = ()=>ajax(KPBASE+'/tbTaxPlateManage/queryTaxPlateByNsrsbhFromThird',{},'post',true)
export const synchronizationTaxPlate = (data)=>ajax(KPBASE+'/tbTaxPlateManage/synchronizationTaxPlate',data,'post',true)
//单页复选框数据 开具二维码发票
export const queryGoodsNoPageByUser = ()=>ajax(KPBASE+'/goodsNo/queryGoodsNoPageByUser',{},'post',true)
// 生成二维码
export const createQrCode = (array)=>ajax(KPBASE+'/simpleInvoice/createQrCode',array,'post',true)
export const emailQrCode = (array)=>ajax(KPBASE+'/simpleInvoice/emailQrCode',array,'post',true)
//个人权限菜单
export const menuPower=()=>ajax(HTBASE+'/resources/select/menu',{},'post',true)
//开发票
export const submitTitle = (data) => ajax(KPBASE + '/simpleInvoice/submitTitle', data, 'post', true)
//获取发票信息
export const getQrInvoiceInfoById = (data) => ajax(KPBASE + '/simpleInvoice/getQrInvoiceInfoById', data, 'post', true)
//检索发票抬头
export const gsDataFind = (data) => ajax(INVOICE, data, 'post', false)
//获取微信卡包授权***
export const getWxCardPkgAuthUrlFromFPTime = (id)=>ajax(KPBASE+'/api/center/wx/getWxCardPkgAuthUrlFromFPTime',id,'post',true)
//网上抄报
export const copyAndReport  = (data)=>ajax(KPBASE+'/copyAndReport/copyAndReport',data,'post',true)
//查询门店信息
export const selectListPage = (data)=>ajax(KPBASE+'/tbShopScan/selectListPage',data,'post',true)
//查询门店信息 按id查
export const selectById = (data)=>ajax(KPBASE+'/tbShopScan/selectById',data,'post',true)
//新增门店数据
export const addStore = (data)=>ajax(KPBASE+'/tbShopScan/add',data,'post',true)
//更新数据
export const update = (data)=>ajax(KPBASE+'/tbShopScan/update',data,'post',true)
//删除数据
export const deleteStore = (data)=>ajax(KPBASE+'/tbShopScan/delete',data,'post',true)
//批量更新税率
export const updateShopSlvBatch = (data)=>ajax(KPBASE+'/tbShopScan/updateShopSlvBatch',data,'post',true)
//导出门店
export const exportShopList =  (data)=>ajax(KPBASE+'/tbShopScan/exportShopList',data,'post',"downFile")
// 二维码提交数据
export const commitInvoiceAudit = (data)=>ajax(KPBASE+'/simpleInvoice/commitInvoiceAudit',data,'post',true)
//门店开票审核
export const consentInvoiceAudit = (data)=>ajax(KPBASE+'/simpleInvoice/consentInvoiceAudit',data,'post',true)
//企业
export const sysEnterpriseDelete = (data)=>ajax(HTBASE+'/sysEnterprise/delete',data,'post',true)
//企业列表
export const querySysEnterpriseList = (data)=>ajax(HTBASE+'/sysEnterprise/querySysEnterpriseList',data,'post',true)
//企业 id查
export const sysEnterpriseGetById = (data)=>ajax(HTBASE+'/sysEnterprise/getById',data,'post',true)
//企业修改信息
export const sysEnterpriseUpdate = (data)=>ajax(HTBASE+'/sysEnterprise/update',data,'post',true)
//企业信息添加
export const sysEnterpriseAdd = (data)=>ajax(HTBASE+'/sysEnterprise/add',data,'post',true)
//查询企业号
export const getBelongEnterprisesByUser = (data)=>ajax(HTBASE+'/sysEnterprise/getBelongEnterprisesByUser',data,'post',true)
//查询统计 余票管理
export const invoiceBuyInfoQuery = (data)=>ajax(KPBASE+'/copyAndReport/invoiceBuyInfoQuery',data,'post',true)
// 获取角色
export const getRolesByUser = (data)=>ajax(HTBASE+'/role/getRolesByUser',data,'post',true)
//第三方获取角色
export const getLowerRolesByUser = (data)=>ajax(HTBASE+'/role/getLowerRolesByUser',data,'post',true)
//反写抄报
export const writeBackListenData = (data)=>ajax(KPBASE+'/copyAndReport/writeBackListenData',data,'post',true)
//状态查询
export const queryStatus = (data)=>ajax(KPBASE+'/tbApplyInvoice/queryStatus',data,'post',true)
// 增加批量开票按钮
export const importInvoice = (data)=>ajax(KPBASE+'/simpleInvoice/importInvoice',data,'post',"upFile")
//发票 打印
export const printInvoice = (data)=>ajax(KPBASE+'/simpleInvoice/printInvoice',data,'post',true)
// 申领信息 添加
export const tbApplyInvoice =  (data)=>ajax(KPBASE+'/tbApplyInvoice/add',data,'post',true)
// 撤销申领
export const tbApplyInvoiceRevocation =  (data)=>ajax(KPBASE+'/tbApplyInvoice/revocation',data,'post',true)
// 监控管理
export const listenManageinfoQuery =  (data)=>ajax(KPBASE+'/copyAndReport/listenManageinfoQuery',data,'post',true)
export const getNsrmcType =  (data)=>ajax(KPBASE+'/copyAndReport/getNsrmcType',data,'post',true)
// 改密码
export const updatePassword = (data)=>ajax(HTBASE+'/user/updatePassword',data,'post',true)
//pdf
export const createDzfpPdf =  (data)=>ajax(KPBASE+'/tbInvoiceFile/createDzfpPdf',data,'post',true)
//勾选发票
export const invoiceChoose =  (data)=>ajax(KPBASE+'/copyAndReport/invoiceChoose',data,'post',true)
//远程登入
export const authToken =  (data)=>ajax(KPBASE+'/invoiceCheck/authToken',data,'post',true)
//远程查询
export const queryCheck =  (data)=>ajax(KPBASE+'/invoiceCheck/queryCheck',data,'post',true)
//远程
export const confirmCheck =  (data)=>ajax(KPBASE+'/invoiceCheck/confirmCheck',data,'post',true)
// 历史
export const checkInventory =  (data)=>ajax(KPBASE+'/invoiceCheck/checkInventory',data,'post',true)
// 一键统计
export const oneClickStatistics =  (data)=>ajax(KPBASE+'/invoiceCheck/oneClickStatistics',data,'post',true)
//一键撤销
export const revocationStatistics =  (data)=>ajax(KPBASE+'/invoiceCheck/revocationStatistics',data,'post',true)
//勾选下载
export const queryApplyClick =  (data)=>ajax(KPBASE+'/invoiceCheck/queryApplyClick',data,'post',true)
//勾选申请
export const applyClickInvoice =  (data)=>ajax(KPBASE+'/invoiceCheck/applyClickInvoice',data,'post',true)
//导出成员
export const exportUserList =  (data)=>ajax(HTBASE+'/user/exportUserList',data,'post',"downFile")

//导出 勾选清单
export const checkInventoryDerive =  (data)=>ajax(KPBASE+'/invoiceCheck/checkInventoryDerive',data,'post',"downFile")
//导出数据 未使用
export const exportSuccessInvoices = (data)=>ajax(KPBASE+'/simpleInvoice/exportSuccessInvoices',data,'post',"downFile")
//发票下载
export const downloadClickInvoice =  (data)=>ajax(KPBASE+'/invoiceCheck/downloadClickInvoice',data,'post',true)
//审计日志
export const selectByPage = (data)=>ajax(HTBASE+"/reviewRecord/selectByPage",data,"post",true)
// 确认收票
export const paperInvoiceAffirm = (data)=>ajax(KPBASE+"/tbApplyInvoice/paperInvoiceAffirm",data,"post",true)
//  source1
export const getAmouontBySecretStrUnderLine = (data)=>ajax(KPBASE+"/weiQian/getAmouontBySecretStrUnderLine",data,"post",true)
export const getAmouontBySecretStrOnLine = (data)=>ajax(KPBASE+"/weiQian/getAmouontBySecretStrOnLine",data,"post",true)
// 线下开票1
export const submitTitleUnderLine = (data)=>ajax(KPBASE+"/weiQian/submitTitleUnderLine",data,"post",true)
//卡包链接
export const getWxCardPkgAuthUrl = (data)=>ajax(WX_KPBASE+"/wx/getWxCardPkgAuthUrl",data,"post",true)
//汇总导出
export const exportInvoiceCollect =  (data)=>ajax(KPBASE+'/copyAndReport/exportInvoiceCollect',data,'post',"downFile")
// 盘组状态列表:
export const getTaxStatusList = (data)=>ajax(KPBASE+"/tbTaxStatus/getTaxStatusList",data,"post",true)
//添加盘组状态表
export const insertTaxStatus = (data)=>ajax(KPBASE+"/tbTaxStatus/insertTaxStatus",data,"post",true)
// 修改盘组信息
export const updateTaxStatus = (data)=>ajax(KPBASE+"/tbTaxStatus/updateTaxStatus",data,"post",true)
// 删除盘组信息
export const deleteTaxStatus = (data)=>ajax(KPBASE+"/tbTaxStatus/deleteTaxStatus",data,"post",true)
// 查询机器编号
export const syncAllTaxPlate = (data)=>ajax(KPBASE+"/tbTaxPlateManage/syncAllTaxPlate",data,"post",true)
// 修改二维码时效
export const updateValidity = (data)=>ajax(HTBASE+"/sysEnterprise/updateValidity",data,"post",true)
//  修复发票
export const invoiceRepair = (data)=>ajax(KPBASE+"/copyAndReport/invoiceRepair",data,"post",true)

// 异常发票监控
export const errorEnterpriseMonitor = (data)=>ajax(KPBASE+"/tbEnterpriseConfigure/errorEnterpriseMonitor",data,"post",true)

export const getAuthStatus = (data)=>ajax(KPBASE+"/qxy/getAuthStatus",data,"post",true)
export const getLoginStatus = (data)=>ajax(KPBASE+"/qxy/getLoginStatus",data,"post",true)
export const getSlStatus = (data)=>ajax(KPBASE+"/qxy/getSlStatus",data,"post",true)
export const sendSms = (data)=>ajax(KPBASE+"/qxy/sendSms",data,"post",true)
export const uploadSms = (data)=>ajax(KPBASE+"/qxy/uploadSms",data,"post",true)
export const getSmsLoginStatus =  (data)=>ajax(KPBASE+"/qxy/getSmsLoginStatus",data,"post",true)